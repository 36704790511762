import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ViewAlertPageRoutingModule } from './view-alert-routing.module';

import { ViewAlertPage } from './view-alert.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ViewAlertPageRoutingModule
  ],
  declarations: [ViewAlertPage]
})
export class ViewAlertPageModule {}
