import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ViewAlertPage } from './view-alert.page';

const routes: Routes = [
  {
    path: '',
    component: ViewAlertPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewAlertPageRoutingModule {}
